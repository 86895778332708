import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import portfolioStyles from "./work.module.scss"
import SEO from "../components/seo"
import { motion, useAnimation, useViewportScroll, useTransform } from "framer-motion"

export default ({ data }) => {
  const { scrollY } = useViewportScroll();
  const yRange = useTransform(scrollY, scrollLength => scrollLength > 200 ? 0 : 1-(scrollLength / 200));
  scrollY.onChange(console.trace);
  return (
    <Layout yRange={yRange}>
        <SEO
          title={"Phil Cohn - Portfolio"}
          description={'Portfolio of custom AR and 3D effects.'}
          image={'/static/favicon.ico'}
        />
        <div className={portfolioStyles.portfolioContainer}>
         <h1>
          Some things I've made:
        </h1>

      <div className={portfolioStyles.portfolioLayout}>
       
        {data.allMarkdownRemark.edges.map(({ node }) => (

          <div key={node.id} className={portfolioStyles.portfolioCard}>
            <Link
              to={node.fields.slug}
            >
              <h3
              >
                {node.frontmatter.title}{" "}

              </h3>
              <Img fluid={node.frontmatter.featuredImage.childImageSharp.fluid} />

            </Link>
          </div>
        ))}
      </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC })
      {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            featuredImage {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`